import instance from '../../axios';

export const createRoles = async (data) => {
  const response = await instance.post('roles/create', data);
  return response.data;
};

export const updateRoles = async (data) => {
  const response = await instance.post('roles/update', data);
  return response.data;
};

export const deleteRoles = async (data) => {
  const response = await instance.post('roles/delete', data);
  return response.data;
};

export const getAllRoles = async () => {
  const response = await instance.get('roles/get-all');
  return response.data;
};

export const getRolesById = async (id) => {
  const response = await instance.get(`roles/get-by-id?id=${id}`);
  return response.data;
};

export const getCurrentRole = async () => {
  const response = await instance.get(`roles/get-current`);
  return response.data;
};
