import React, { Suspense, createContext, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './scss/style.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Authentication from './utils/auth/Authentication';

export const Loading = () => { 
    return (
        <div className="pt-3 text-center">
            <div className="sk-spinner sk-spinner-pulse"></div>
        </div>
    ) 
};

// Contexts
export const AuthContext = createContext(new Authentication());

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const App = () => {
    // const [Auth, _] = useState(new Authentication())
    const [Auth, setAuth] = useState(new Authentication())
    const [fetched, setFetched] = useState(false)
    useEffect(() => {
        if (Auth.isEmpty() && localStorage.getItem('userDetail')){
            var tmp = new Authentication()
            tmp.fetchPermissions().then(() => {
                setAuth(tmp)
                setFetched(true)
            })
        } else {
            setFetched(false)
        }
    }, [])

    // useEffect(() => {
    //     console.log("changed")
    // }, [Auth])
    return (
        <BrowserRouter>
            <Suspense fallback={Loading}>
                <AuthContext.Provider value={Auth}>
                    <Routes>
                        <Route exact path="/login" name="Login Page" element={<Login />} />
                        {/* <Route exact path="/register" name="Register Page" element={<Register />} /> */}
                        <Route path="/*" name="Home" element={<DefaultLayout />} />
                        <Route exact path="/404" name="Page 404" element={<Page404 />} />
                        <Route exact path="/500" name="Page 500" element={<Page500 />} />
                        {/* <Route path="/" name="Login" element={<Login />} /> */}
                    </Routes>
                </AuthContext.Provider>
            </Suspense>
        </BrowserRouter>
    );
}

export default App;
